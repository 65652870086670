import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ConfirmName from "./components/confirmName";
// import Congrats from "./components/congrats";
// import Faqu from "./components/faqu";
// import Questionaire from "./components/questionaire";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ConfirmName />} />
        {/* <Route path="/quizz" element={<Questionaire />} /> */}
        {/* <Route path="/faqu" element={<Faqu />} /> */}
        {/* <Route path="/congrats" element={<Congrats />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
