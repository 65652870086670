import React from "react";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import "./confirmName.css";
// import Snowfall from "./helpers/snowfall";
//
function ConfirmName() {
  // const [name, setName] = useState("");
  // const navigate = useNavigate();
  // const [error, setError] = useState("");
  //
  // const handleChange = (event) => {
  //   setError("");
  // };
  //
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //
  //   if (name.toLowerCase() === "ronja") {
  //     localStorage.setItem("name", name);
  //     navigate("/Faqu");
  //   } else if (name.length === 0) {
  //     setError("Don't play with me you little shhhhh....");
  //     console.log(error);
  //   } else if (name.toLowerCase() === "no") {
  //     setError("No name - no play - no gift. Think about it.");
  //   } else {
  //     setError("You either insert the right name or fuck off from here <3");
  //   }
  //   console.log(`The name entered was: ${name}`);
  // };

  return (
    <div className="name_block">
      Hi there!
      {/* <Snowfall /> */}
      {/* <form */}
      {/*   className="form_name_block" */}
      {/*   onSubmit={handleSubmit} */}
      {/*   onChange={handleChange} */}
      {/* > */}
      {/*   <label className="name_label"> */}
      {/*     Enter your name */}
      {/*     <input */}
      {/*       className="name_input" */}
      {/*       type="text" */}
      {/*       value={name} */}
      {/*       onChange={(e) => setName(e.target.value)} */}
      {/*     /> */}
      {/*   </label> */}
      {/*   <button className="button-30" type="submit"> */}
      {/*     Say Hello :) */}
      {/*   </button> */}
      {/* </form> */}
      {/* {error.length > 0 ? <div className="error_block">{error}</div> : ""} */}
    </div>
  );
}

export default ConfirmName;
